<template>
  <span class="form-error" :v-if="field.error">{{ field.error }}</span>
</template>

<script>

  import { toRefs } from 'vue'

  import useFormField from '@/composables/useFormField'

  export default {
    props: {
      formName: {
        type: String,
        required: true,
      },
      fieldName: {
        type: String,
        required: true,
      },
    },
    setup(props) {
      return useFormField(toRefs(props))
    },
  }

</script>

<style lang="stylus" scoped>

  .form-error
    @apply mt-1
    @apply text-sm
    @apply leading-5
    @apply text-danger-700

    min-height: 1.25rem

</style>
