<template>
  <span class="form-note" :v-if="field.note" v-html="field.note" />
</template>

<script>

  import { toRefs } from 'vue'

  import useFormField from '@/composables/useFormField'

  export default {
    props: {
      formName: {
        type: String,
        required: true,
      },
      fieldName: {
        type: String,
        required: true,
      },
    },
    setup(props) {
      return useFormField(toRefs(props))
    },
  }

</script>

<style lang="stylus" scoped>

  .form-note
    @apply mt-1
    @apply text-sm
    @apply leading-5
    @apply text-gray-600

    min-height: 1.25rem

</style>
