<template>
  <label
    v-if="!!field.label"
    :class="{ 'required': field.required }"
    :for="shouldUseForAttribute ? uuid : null"
  >
    {{ field.label }}
    <span class="optional" v-if="field.explicitlyOptional">- Optional</span>
  </label>
</template>

<script>

  import { toRefs } from 'vue'

  import useFormField from '@/composables/useFormField'

  export default {
    props: {
      formName: {
        type: String,
        required: true,
      },
      fieldName: {
        type: String,
        required: true,
      },
    },
    setup(props) {
      return useFormField(toRefs(props))
    },
    computed: {
      shouldUseForAttribute() {
        if (this.field.type === 'radio') return false
        if (this.field.type === 'checkbox' && this.field.metaType === 'multi') return false
        return true
      },
    },
  }

</script>

<style lang="stylus" scoped>

</style>
