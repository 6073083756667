<template>
  <span class="form-label-description" :v-if="field.labelDescription" v-html="field.labelDescription" />
</template>

<script>

  import { toRefs } from 'vue'

  import useFormField from '@/composables/useFormField'

  export default {
    props: {
      formName: {
        type: String,
        required: true,
      },
      fieldName: {
        type: String,
        required: true,
      },
    },
    setup(props) {
      return useFormField(toRefs(props))
    },
  }

</script>

<style lang="stylus" scoped>

  .form-label-description
    @apply mb-2
    @apply text-gray-600

</style>
