import { useStore } from 'vuex'
import { ref, watch, computed } from 'vue'

export default (props) => {

  // composables
  const store = useStore()

  // make sure the form and field exists first!
  if (!store.state.forms[props.formName.value]) {
    throw new Error(`Invalid formName specified: form "${props.formName.value}" does not exist`)
  }

  if (!store.state.forms[props.formName.value].fields[props.fieldName.value]) {
    throw new Error(`Invalid fieldName specified: field "${props.fieldName.value}" does not exist in form "${props.formName.value}"`)
  }

  // $refs
  const formElement = ref(null)

  // computed
  const field = computed(() => {
    return store.state.forms[props.formName.value].fields[props.fieldName.value]
  })

  const isFieldValid = computed(() => {
    return store.getters['forms/isFieldValid'](props.formName.value, props.fieldName.value)
  })

  const optionGroupNames = computed(() => {

    if (!field.value.options) return []

    const groupNames = new Set()

    field.value.options.forEach((option) => {
      groupNames.add(option.optionGroupName || 'OTHER')
    })

    return Array.from(groupNames)

  })

  const hasOptionsWithDescriptions = computed(() => {
    if (!field.value.options) return false
    return field.value.options.some((option) => {
      return Object.hasOwnProperty.call(option, 'description')
    })
  })

  const uuid = computed(() => {
    return `${props.formName.value}.${props.fieldName.value}`
  })

  watch(field.value, () => {

    const newValue = typeof field.value.displayValue === 'string'
      ? field.value.displayValue
      : field.value.value

    // for array-type values (e.g. "multi checkbox" fields), we'll consider
    //  the field dirty if an option has ever been added
    const isEmpty = Array.isArray(newValue)
      ? newValue.length === 0
      : !newValue

    if (field.value.isDirty) {

      store.dispatch('forms/VALIDATE_FIELD', {
        fieldName: props.fieldName.value,
        formName: props.formName.value,
      })

      if (isEmpty) {

        store.commit('forms/SET_FIELD_IS_DIRTY', {
          fieldName: props.fieldName.value,
          formName: props.formName.value,
          newValue: field.value.required,
        })

        if (field.value.error && !field.value.required) {
          store.commit('forms/SET_FIELD_ERROR', {
            fieldName: props.fieldName.value,
            formName: props.formName.value,
            newValue: null,
          })
        }

      }

      return

    }

    // otherwise the field is not yet dirty, so mark it dirty as long as the
    //  field is not empty
    if (isEmpty) return

    store.commit('forms/SET_FIELD_IS_DIRTY', {
      fieldName: props.fieldName.value,
      formName: props.formName.value,
      newValue: true,
    })

  })

  // methods
  const focus = () => {
    formElement.value.focus()
  }

  return {
    uuid,
    focus,
    field,
    formElement,
    isFieldValid,
    optionGroupNames,
    hasOptionsWithDescriptions,
  }

}
